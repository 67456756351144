/*! General styling */

*,
::after,
::before {
    box-sizing: border-box
}

label,
table {
    display: block
}

a {
    color: inherit;
    text-decoration: underline
}

blockquote,
ol,
p,
pre,
table,
ul {
    margin-top: 1.5625rem;
    margin-bottom: 1.5625rem
}

hr {
    height: 0;
    border: 1px solid;
    margin: -1px 0
}

ol ol,
ol ul,
ul ol,
ul ul {
    margin-top: 0;
    margin-bottom: 0
}

b,
code,
em,
small,
strong {
    line-height: 1
}

sub,
sup {
    position: relative;
    top: -.4em
}

sub {
    bottom: -.25em;
    top: .4em
}

th {
    font-weight: 600
}

td,
th {
    border-bottom: .1em solid;
    padding: 1em
}

thead th {
    border-bottom-width: .2em;
    padding-bottom: .5em;
    text-align: left
}

table {
    border-collapse: collapse;
    border-spacing: 0;
    max-width: 100%;
    overflow-x: auto
}

fieldset {
    border: none;
    min-width: 0px
}

button,
input,
select,
textarea {
    display: block;
    padding: .5em
}

label {
    margin-bottom: 1em
}

.button,
button,
input[type=reset],
input[type=submit] {
    text-decoration: none;
    border-radius: .2em;
    cursor: pointer;
    display: inline-block;
    margin: 1em;
    padding: .5em 1em;
    text-align: center;
    outline: 0;
    border: 2px solid;
    background: 0 0
}

button[disabled],
input[type=reset][disabled],
input[type=submit][disabled] {
    background: #e6e6e6;
    color: #b3b3b3
}

button[type=submit],
input[type=submit] {
    background: #367ac3;
    color: #fff
}

button[type=submit]:hover,
input[type=submit]:hover {
    background: #255587;
    color: #bfbfbf
}

input[type=color],
input[type=date],
input[type=datetime-local],
input[type=datetime],
input[type=email],
input[type=file],
input[type=month],
input[type=number],
input[type=password],
input[type=phone],
input[type=range],
input[type=search],
input[type=tel],
input[type=text],
input[type=time],
input[type=url],
select,
textarea {
    border: 1px solid #ccc;
    margin-bottom: 1em;
    padding: .3em .5em;
    border-radius: .2em;
    max-width: 100%
}

input[type=checkbox],
input[type=radio] {
    float: left;
    line-height: 2em;
    margin-right: .5em;
    margin-top: .5em
}

.responsive-video {
    padding-top: 25px;
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    margin: 0 0 1em
}

.responsive-video iframe {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    max-width: 100%
}

.container {
    max-width: var(--container-max-width);
    margin: 0 auto
}