:root {
    --padding: 1%; /* Must be a percentage value if you want IE10 compatability */
    --margin: 1%; /* Must be a percentage value if you want IE10 compatability */
    --vertical-padding: 1em; /* must NOT be a percentage value */
    --vertical-margin: 1em; /* must NOT be a percentage value */
    --breakpoint-m: 1100px;
    --breakpoint-s: 800px;
    --breakpoint-xs: 500px;
    --breakpoint-menu: 1100px;
    --container-max-width: 1100px;
    --fixed-header-height: 10vh;
    
    /* change settings for headings */
    --header-bg-color: white;
    --header-padding: 1.5em 0 0;
    
    
    /* change colors for topmenu */
    --topmenu-bg: #20599E;
    --topmenu-color: white;
    --topmenu-li-a-padding: 10px 15px;
}

@import "/theme/intendit/css/modules/normalize.css";
    @import "/cssmodules/leaflet2.css";
    @import "/theme/intendit/css/modules/basic_styling.css";
@import "/theme/intendit/css/modules/flex_grid.css";
@import "/theme/intendit/css/modules/slick.css";
@import "/theme/intendit/css/modules/slick-theme.css";
@import "/theme/intendit/css/modules/menu.css";
@import "/theme/intendit/css/modules/font-awesome.4.5.0.css";
@import "/theme/intendit/css/modules/magnific-popup.css";

/*
@import "/theme/intendit/css/modules/photoswipe.css";
@import "/theme/intendit/css/modules/animate.css";
*/

/* general
---------------------------------------------------------*/

section {
    min-height:50vh;
    padding:5% 0;
}

.white {
    background-color:white;
}

.gray {
    background-color:#eee;
}

.darkgray {
    background-color:#222;
}
.blue {
    background-color:#20599E;
}

#menu-icon {
    z-index:100;
}

#menu-icon span {
    border:2px solid white;
}

#menu-icon .txt {
    border:none !important;
    padding-left:0.5em;
    color:white;
}

.responsive-menu-icon {
    background:#20599E;
    width:100%;
}

.page {
    min-height:80vh;
}

.page .content ul {
    margin:0;
    padding:0;
    list-style-type: none;
}

.page .content li {
  padding-left: 1.2em;
}

.page .content li:before {
  content: "\f00c"; /* FontAwesome char code inside the '' */
  font-family: FontAwesome; /* FontAwesome or whatever */
  display: inline-block;
  width: 1.2em; /* same as padding-left set on li */
  margin-left: -1.2em; /* same as padding-left set on li */
}
    

/* header
---------------------------------------------------------*/
header {
    background:var(--header-bg-color);
    text-transform:none;
    padding:var(--header-padding);
    box-shadow: 0 2px 3px 0 rgba(0,0,0,0.2);
    z-index:10;
    border-bottom:#20599E solid 4px;
}

header .container {
    padding:0 0.5em;
}

header a {
    color:black;
}

.topsocial {
    border-bottom:#eee solid 1px;
flex-grow: 0;
}

.four img{
    width: 100%;
}

.topsocial ul {
    margin:0;
    padding:0;
    list-style:none;
}

.topsocial li {
    display:inline-block;
    margin-right:0.5em;
}

.topsocial li a {
    text-decoration:none;
}


.menu li {
    margin:0;
}

.menu li a {
    padding:var(--topmenu-li-a-padding);
    color:black;
}

.menu li a:hover, .menu .active a {
    background: var(--topmenu-bg);
    color:var(--topmenu-color);
}

.menu .dropdown ul {
    background:#1E579C;
    z-index:100;
}

.menu .dropdown ul li a {
    color:#9FCBFF;
}

.menu .dropdown ul li a:hover {
    color:#fff;
}

.logo {
    padding:0;
}

.logo a {
    font-size:0;
}

/* slider
---------------------------------------------------------*/

.slick-slider {
    margin-bottom:0;
}

.slider-caption-outer .container {
    height: 100%;
    position: relative;
    text-align: left;
    display: inline-block;
}

.slick-prev, .slick-next {
    width:auto;
    height:auto;
}

.slick-next {
    right:50px;
    z-index:100;
}

.slick-prev {
    left:50px;
    z-index:100;
}

.slick-next:hover, .slick-prev:hover {
    border:none;
}

.slick-next:before, .slick-prev:before {
    font-size:5rem;
}

.slick-dots {
    bottom:10px;
}

.slick-dots li button:before {
    font-size:0.8rem;
    opacity:1.0;
    color:#fff;
}

.slick-dots li.slick-active button:before {
    color:#f2941c;
}

.slick-dots li {
    border:#D0D0D0 solid 1px;
    border-radius:50%;
    margin:0 2px;
}

.slick-dots li button {
    margin:0;
}

.slider-caption-outer {
    background:rgba(0,0,0,0.4);
}

.slider-caption {
    color:white;
}

.slider-caption h1, .slider-caption h2 {
    color:white;
    font-size:3.0rem;
}

.slider-caption .button {
    background:black;
    border:#101010 solid 1px;
    color:white;
}

.slider-caption .button:hover {
    background:#f2941c;
    border:#f2941c solid 1px;
    color:white;
}
    

/* Startbox
---------------------------------------------------------*/

.box-text {
    text-align:center;
}

.box-text h2 {
    margin:0;
    color:#000;
    font-size:2.0rem;
    letter-spacing:-1.5px;
    text-transform:uppercase;
}

.start-box {
    padding:0;
    margin:0;
    list-style:none;
}

a.cat-box {
    display: block;
    height: 100%;
    width: 100%;
    color: white;
    text-shadow: 1px 1px 1px #000;
    text-align: center;
    background-size: cover;
    text-transform: uppercase;
    text-decoration:none;
    font-size:2.2rem;
    font-weight: 700;
    border-right:#eee solid 4px;

}

.cat-box h2 {
    height: 100%;
    width: 100%;
    font-size: 1em;
    font-weight: 500;
    color: #fff;
    line-height: 242px;
    background: transparent;
    transition: background 0.5s;
    border-bottom: none;
}

.cat-box h2:hover {
}

.start-block-wrapper3 .container > h2 {
    text-align:center;
}

.boxslick .slick-prev {
    left:-50px;
}

.boxslick .slick-next {
    right:-50px;
}

.boxslick .slick-prev:before, .boxslick .slick-next:before {
    color:black;
}

.gallery-item ul {
    margin:0;
    padding:0;
    list-style:none;
}


/* block2
---------------------------------------------------------*/
section.start-block-wrappe2 {
    padding:1.5em 0;
    min-height:5vh;
}

.block2-content {
    text-align:center;
    max-width:850px;
    margin:0 auto;
    width:100%;
}

/* block3
---------------------------------------------------------*/
.start-block-wrapper3 .container > h2 {
    text-align:center;
}

/* block4
---------------------------------------------------------*/
.start-block-wrapper4 {
}

.start-block-wrapper4 h3 {
    font-weight:bold;
}

.start-block-wrapper4 button {
    transition: 0.5s ease;
    -o-transition: 0.5s ease;
    -webkit-transition: 0.5s ease;
}

/* block5
---------------------------------------------------------*/
section.start-block-wrapper5 {
    padding:0;
}

/* Footer
---------------------------------------------------------*/

footer {
    color:white;
}

footer a {
    color: white;
    text-decoration:none;
}

footer a:hover {
    color:#F28D1A;
}

.socialamedia a {
    font-size:2.0rem;
    padding-right:0.5em;
}

footer h4 {
    font-size:1.4rem;
    color:white;
}


/* other
---------------------------------------------------------*/

.contactpage span {
    display:block;
}

.contactpage .contactinfo {
    margin-top:1em;
}

.fa-map-marker:before {
    font-size:3.0rem;
    color:#20599E;
}

.boltform label {
    display: none;
}


.boltform textarea {
    min-height:120px;
}

input[type="email"], input[type="number"], input[type="search"], input[type="text"], input[type="tel"], input[type="url"], input[type="password"], textarea {
    width: 100%;
    color:#222;
}

.button, button, input[type=reset], input[type=submit] {
    margin:0;
    background:#F28D1A;
    border:#F28D1A solid 1px;
    color:white;
    transition: 0.5s ease;
    -o-transition: 0.5s ease;
    -webkit-transition: 0.5s ease;
}

.button:hover, button:hover, input[type=reset]:hover, input[type=submit]:hover {
    background:#000;
    border:#000 solid 1px;
    color:white;
}

button[type=submit], input[type=submit] {
    background:#F28D1A;
}

button[type=submit]:hover, input[type=submit]:hover {
    background:#000;
    border:#000 solid 1px;
    color:white;
}

a {
    color:#20599E;
    transition: 0.5s ease;
    -o-transition: 0.5s ease;
    -webkit-transition: 0.5s ease;
}

a:hover {
    color:#000;
}

body {
    font-family: 'PT Sans', sans-serif;
    font-weight: 400;
    color:#282d32;
}

.container {
    max-width: 1100px;
    padding: 0.5em;
    width: 100%;
}

img {
    max-width: 100%;
    height: auto;
}

/* Give map a height and it's images no max-width */
.map-canvas {
    height: 50vh;
}

.map-canvas img {
    max-width: none;
}

h1, h2, h3, h4, h5, h6 {
    color:#1F589F;
    margin:0;
}

h1 {
    font-weight:700;
}

/* Make FB widget responsive */
.fb_iframe_widget,
.fb_iframe_widget span,
.fb_iframe_widget iframe[style],
.twitter-timeline {
    width: 100% !important;
}

/* Example media queries*/
@media (max-width: 400px) {}

@media (max-width: 550px) {}

@media (max-width: 750px) {
    .topsocial {
        display:none;
    }
    .boxslick .slick-prev, .boxslick .slick-next, .slick-dots {
        display:none !important;
    }
    .cat-box h2 {
        font-size:1.2rem;
    }

}

@media (max-width: var(--breakpoint-menu)) {
    .menu.show {
        background:white;
    }
    .logo {
        margin-bottom:1em;
    }
}

@media (min-width: 990px) {
}

@media (min-width: var(--breakpoint-menu)) {
    .slider-caption-outer {
    bottom: 0;
    left: 0;
    overflow: visible;
    position: absolute;
    text-align: center;
    right: 0;
    top: 0;
    }
    .slider-caption {
    position: absolute;
    bottom: 50%;
    width: 100%;
    right: 0;
    text-align: center;
    display: block;
    padding: 0;
    z-index: 3;
    }
}

@media (min-width: var(--breakpoint-xs)) {
    .topmenu {
        margin-top:1.5em;
    }
    .start-block-wrapper4 h3 {
        font-size:2.0rem;
    }
}


@media (max-width: var(--breakpoint-m)) {}
@media (max-width: var(--breakpoint-s)) {}
@media (max-width: var(--breakpoint-xs)) {}
